
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
.component {
    $p: &;

    @apply flex flex-col items-start gap-3 lg:gap-4;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    b,
    strong {
        @apply font-bold;
    }

    h1 {
        @apply text-h1-d lg:text-h1;
    }

    h2 {
        @apply text-h2-d lg:text-h2;
    }

    h3 {
        @apply text-h3-d lg:text-h3;
    }

    h4 {
        @apply text-h4-d lg:text-h4;
    }

    h5 {
        @apply text-h5-d lg:text-h5;
    }

    h6 {
        @apply text-p1-d lg:text-p1;
    }

    p {
        @apply self-stretch;
    }

    ul,
    ol {
        list-style: reset;
    }

    ul {
        @apply pl-4;
    }

    ol {
        @apply pl-5;
    }

    a {
        @apply text-blue-bright underline hover:no-underline;
    }

    blockquote {
        @apply rounded-lg border border-gray-03 bg-gray-05 px-4 py-2;
    }

    code {
        @apply rounded-lg bg-gray-01 px-4 py-2 text-p2-d text-white lg:text-p2;
    }

    img {
        @apply rounded-lg lg:rounded-2xl;

        width: auto;
    }
}
